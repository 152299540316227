import { render } from "preact";
import { App } from "./app";
import { ShopifyCart, ShopifyProduct } from "./types/types";
import { ShortVideosModalContextProvider } from "./context/ShortVideosModalContext";
import { ApiContextProvider } from "./lib/api";

type InstasellLiveEmbedConfig = {
  element: HTMLElement;
  playlistId?: string;
  widgetType: "shoppable-reels" | "live";
  addToCart: (variantId: string, via: "REELS" | "LIVE", handle: string) => Promise<void>;
  getProductDetailsBySlug: (slug: string) => Promise<ShopifyProduct>;
  getCurrentCart: () => Promise<ShopifyCart>;
  getCurrentCartId: () => Promise<string>;
  getCurrencyCode: () => string;
  redirectToCart: () => void;
  skeletonLength: number;
  getPageType: () => "home" | "product";
  getActiveProductPageId: (() => string) | null;
  showFeedOnHomePageOnly: boolean;
};

const shopifyRoot = (window as any).Shopify.routes.root;

const defaultConfig: InstasellLiveEmbedConfig = {
  element: document.querySelector("#instasell-live-short-videos")!,
  widgetType: "shoppable-reels",
  async addToCart(variantId, _via, handle) {
    await fetch(shopifyRoot + "products/" + handle + ".js")
      .then((res) => res.json())
      .catch((error) => console.error(error));

    await fetch(shopifyRoot + "cart/add.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        items: [
          {
            id: variantId,
            quantity: 1,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .catch((error) => console.log(error));
  },
  async getCurrentCart() {
    return await fetch(shopifyRoot + "cart.json")
      .then((res) => res.json())
      .catch((error) => console.log(error));
  },
  async getCurrentCartId() {
    return await fetch(shopifyRoot + "cart.json")
      .then((res) => res.json())
      .then((res) => res.token)
      .catch((error) => console.log(error));
  },
  async getProductDetailsBySlug(slug) {
    return await fetch(shopifyRoot + "/products/" + slug + ".js")
      .then((res) => res.json())
      .catch((error) => console.log(error));
  },
  getCurrencyCode() {
    return (window as any).Shopify.currency.active;
  },
  redirectToCart() {
    window.location.href = "/cart/checkout";
  },
  skeletonLength: 6,
  getPageType() {
    if (location.pathname.startsWith("/products/") || location.pathname.startsWith("/collections/")) {
      return "product";
    } else {
      return "home";
    }
  },
  getActiveProductPageId: null,
  showFeedOnHomePageOnly: false,
};

export const instasellLiveEmbedConfig: InstasellLiveEmbedConfig = Object.assign(defaultConfig, (window as any).__INSTASELL_LIVE_CONFIG__ || {});

render(
  <ApiContextProvider>
    <ShortVideosModalContextProvider>
      <App />
    </ShortVideosModalContextProvider>
  </ApiContextProvider>,
  instasellLiveEmbedConfig.element as HTMLElement
);
