import { useState } from "preact/hooks";
import { instasellLiveEmbedConfig } from "../..";
import { useShortVideosModalContext } from "../../context/ShortVideosModalContext";
import { useApi } from "../../lib/api";
import { formatCurrency } from "../../lib/utils";

type ProductDetailsModalProps = {};

const ProductDetailsModal = ({}: ProductDetailsModalProps) => {
  const useBuyNow = true;
  let currencyCode = instasellLiveEmbedConfig.getCurrencyCode?.() || "INR";
  const api = useApi();
  const {
    shortVideos,
    activeVideoId,
    activeProductIndex,
    isDesktop,
    isProductDetailsModalOpen,
    setIsProductDetailsModalOpen,
  } = useShortVideosModalContext();
  const activeVideo = activeVideoId
    ? shortVideos.find((v) => v.id === activeVideoId) || null
    : null;
  const product = activeVideo ? activeVideo.products[activeProductIndex] : null;
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    null
  );

  const [showAddedToCartMessage, setShowAddedToCartMessage] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);

  const handlePurchase = async () => {
    try {
      if (product?.variants[0].providerVariantId) {
        setAddingToCart(true);
        try {
          await instasellLiveEmbedConfig.addToCart(
            selectedVariantId
              ? selectedVariantId!
              : product?.variants[0].providerVariantId,
            "REELS",
            product.handle
          );
        } catch (error) {
          const arr = product.permalink.split("/");
          const productSlug = arr[arr.length - 1];
          const productDetails =
            await instasellLiveEmbedConfig.getProductDetailsBySlug(productSlug);
          console.log(productDetails);
        }
        const cart = await instasellLiveEmbedConfig.getCurrentCart();
        if (!cart) return;
        const cartId = cart.token;
        api.shortVideosBoron({
          eventType: "addToCart",
          addToCart: {
            shortVideoId: activeVideo?.id!,
            productId: product.productId,
            providerCartId: cartId,
            variantId: selectedVariantId
              ? selectedVariantId!
              : product?.variants[0].providerVariantId,
            quantity: 1,
          },
        });
        if (useBuyNow) {
          instasellLiveEmbedConfig.redirectToCart();
        }
        setAddingToCart(false);
        setShowAddedToCartMessage(true);
        window.setTimeout(() => {
          setShowAddedToCartMessage(false);
        }, 2000);
      }
    } catch (error) {
      setAddingToCart(false);
      console.error(error);
    }
  };

  const closeModal = () => {
    setIsProductDetailsModalOpen(false);
  };

  // TODO: this code should be removed after the following site updates their config
  switch (window.location.hostname) {
    case "instasell-live-commerce.webflow.io":
    case "instasell.io":
    case "www.twoprettygirlz.com":
    case "two-pretty-girlz.myshopify.com":
      currencyCode = "USD";
      break;
  }

  return (
    <>
      <div
        className={`${
          isProductDetailsModalOpen
            ? "ins-product-details-modal-overlay__show"
            : "ins-product-details-modal-overlay__hidden"
        } ${
          isDesktop
            ? "ins-product-details-modal-overlay__is-desktop"
            : "ins-product-details-modal-overlay__is-mobile"
        }`}
        onClick={closeModal}
      />
      <div
        className={`ins-product-details-modal ${
          isProductDetailsModalOpen
            ? "ins-product-details-modal__show"
            : "ins-product-details-modal__hidden"
        } ${
          isDesktop
            ? "ins-product-details-modal__is-desktop"
            : "ins-product-details-modal__is-mobile"
        }`}
      >
        <div
          className={`${
            isProductDetailsModalOpen
              ? "ins-product-details-modal-close-button__show"
              : "ins-product-details-modal-close-button__hidden"
          }`}
          onClick={closeModal}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 25.6667C10.905 25.6695 7.93587 24.4412 5.74735 22.2527C3.55882 20.0642 2.33055 17.0951 2.33333 14.0001V13.7667C2.42875 9.09098 5.30577 4.92357 9.64409 3.17696C13.9824 1.43035 18.9444 2.44177 22.253 5.74706C25.5925 9.08382 26.592 14.1043 24.7851 18.4656C22.9781 22.827 18.7209 25.6695 14 25.6667ZM14 15.6451L17.0217 18.6667L18.6667 17.0217L15.645 14.0001L18.6667 10.9784L17.0217 9.33339L14 12.3551L10.9783 9.33339L9.33334 10.9784L12.355 14.0001L9.33334 17.0217L10.9783 18.6667L14 15.6462V15.6451Z"
              fill="white"
            ></path>
          </svg>
        </div>
        <div class="ins-product-details-section">
          <header class="ins-product-details-section-header">
            <h2 class="ins-product-details-heading">Shop</h2>
            <hr class="ins-product-details-divider"></hr>{" "}
          </header>
          {product ? (
            <div class="ins-product-details-container">
              <header class="ins-product-details-header">
                <div
                  style={{
                    "background-image": `url(${product?.image})`,
                  }}
                  class="ins-product-details-image"
                />
                <div class="ins-product-details-info">
                  <h2 class="ins-product-details-name">{product?.title}</h2>
                  <p class="ins-product-details-price ">
                    {formatCurrency(
                      product?.variants[0]?.price || 0,
                      currencyCode
                    )}
                    {product?.variants[0]?.comparePrice ? (
                      <span class="ins-product-details-strikeoff-price">
                        {formatCurrency(
                          product?.variants[0]?.comparePrice || 0,
                          currencyCode
                        )}
                      </span>
                    ) : null}
                  </p>
                </div>
              </header>
              {product?.variants.length && product?.variants.length > 1 ? (
                <div class="ins-product-details-variants-list">
                  {product?.variants.map((variant) => (
                    <button
                      class={`ins-product-details-variants-item ${
                        variant.providerVariantId == selectedVariantId
                          ? "ins-product-details-variants-item__active"
                          : ""
                      }`}
                      onClick={() =>
                        setSelectedVariantId(variant.providerVariantId)
                      }
                    >
                      {variant.variation}
                    </button>
                  ))}
                </div>
              ) : null}
              <button
                class="ins-product-details-buy-now-button"
                onClick={handlePurchase}
              >
                {/* {useBuyNow
                  ? addingToCart
                    ? "Preparing your cart..."
                    : "Buy Now"
                  :  */}
                {addingToCart ? "Adding to  your cart..." : "Add to cart"}
                {/* } */}
                {showAddedToCartMessage ? (
                  <span class="ins-product-details-buy-now-success-message">
                    +Added to cart
                  </span>
                ) : null}
              </button>

              {useBuyNow ? null : (
                <button
                  class="ins-product-details-checkout-button"
                  onClick={() => instasellLiveEmbedConfig.redirectToCart()}
                >
                  Checkout
                </button>
              )}
              <h3 class="ins-product-details-description-heading">
                Description
              </h3>
              <div
                class="ins-product-details-description-container"
                dangerouslySetInnerHTML={{ __html: product?.description }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProductDetailsModal;
