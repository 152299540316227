import "./styles/index.scss";
import ShowShoppableReels from "./components/ShowShoppableReels";

export function App() {
  return (
    <>
      <ShowShoppableReels />
    </>
  );
}
