import { createContext } from "preact";
import { memo } from "preact/compat";
import { useContext, useMemo, useState } from "preact/hooks";
import { ShortVideo } from "../types/api";

type IShortVideoContext = {
  shortVideos: ShortVideo[];
  setShortVideos: (shortVideos: ShortVideo[]) => void;
  isLoadingShortVideos: boolean;
  setIsLoadingShortVideos: (isLoadingShortVideo: boolean) => void;
  isDesktop: boolean;
  setIsDesktop: (isDesktop: boolean) => void;
  isProductDetailsModalOpen: boolean;
  setIsProductDetailsModalOpen: (isProductDetailsModalOpen: boolean) => void;
  activeVideoId: string | null;
  setActiveVideoId: (activeVideoId: string | null) => void;
  activeProductIndex: number;
  setActiveProductIndex: (activeProductIndex: number) => void;
  isPipActive: boolean;
  setIsPipActive: (isPipActive: boolean) => void;
  showFeed: boolean;
  setShowFeed: (showFeed: boolean) => void;
};

const ShortVideosModalContext = createContext<IShortVideoContext>(null as never);

export const ShortVideosModalContextProvider = memo(function ShortVideosModalContextProvider({
  children,
}: React.PropsWithChildren) {
  const [isLoadingShortVideos, setIsLoadingShortVideos] = useState(true);
  const [shortVideos, setShortVideos] = useState<ShortVideo[]>([]);
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const [activeVideoId, setActiveVideoId] = useState<string | null>(null);
  const [isPipActive, setIsPipActive] = useState(false);
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
  const [showFeed, setShowFeed] = useState(true);

  const ShortVideoContext = useMemo<IShortVideoContext>(() => {
    return {
      isLoadingShortVideos,
      setIsLoadingShortVideos,
      shortVideos,
      setShortVideos,
      activeProductIndex,
      setActiveProductIndex,
      activeVideoId,
      setActiveVideoId: (activeVideoId: string | null) => {
        setActiveVideoId(activeVideoId);
        if (activeVideoId) {
          localStorage.setItem("__IS_LAST_ACTIVE_SV", JSON.stringify(activeVideoId));
        } else {
          localStorage.removeItem("__IS_LAST_ACTIVE_SV");
        }
      },
      isPipActive,
      setIsPipActive,
      isProductDetailsModalOpen,
      setIsProductDetailsModalOpen,
      isDesktop,
      setIsDesktop,
      showFeed,
      setShowFeed,
    };
  }, [
    isLoadingShortVideos,
    shortVideos,
    activeProductIndex,
    activeVideoId,
    isPipActive,
    isProductDetailsModalOpen,
    isDesktop,
    showFeed,
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ShortVideosModalContext.Provider value={ShortVideoContext}>
      {children}
    </ShortVideosModalContext.Provider>
  );
});

export const useShortVideosModalContext = () => {
  const shortVideosContext = useContext(ShortVideosModalContext);
  return shortVideosContext;
};
