import { useEffect, useRef } from "preact/hooks";
import { classNames } from "../../lib/utils";
import { useApi } from "../../lib/api";
import { useShortVideosModalContext } from "../../context/ShortVideosModalContext";
import Reel from "../VideoModalReel";

export const VideoModal = ({}: {}) => {
  const api = useApi();
  const {
    shortVideos,
    activeVideoId,
    setActiveVideoId,
    setIsProductDetailsModalOpen,
    isPipActive,
    setIsPipActive,
  } = useShortVideosModalContext();
  const isDesktop = window.innerWidth > 600;
  const modalContentRef = useRef<HTMLDivElement | null>(null as never);
  const activeVideo = shortVideos.find((video) => video.id === activeVideoId);
  const reelPlayerModalRef = useRef<HTMLDivElement>(null as never);

  useEffect(() => {
    if (!activeVideoId || isPipActive) return;
    const previousValue = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = previousValue ? previousValue : "";
    };
  }, [activeVideoId, isPipActive]);

  const onView = async (shortVideoId: string) => {
    try {
      await api.shortVideosBoron({
        eventType: "shortVideoView",
        shortVideoView: {
          shortVideoId: shortVideoId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleModalClose = () => {
    setActiveVideoId(null);
    setIsProductDetailsModalOpen(false);
  };

  const handleClick = (e: MouseEvent) => {
    if (!modalContentRef.current?.contains(e.target as HTMLElement)) {
      setIsProductDetailsModalOpen(false);
      setIsPipActive(true);
    }
  };

  const onClosePip = () => {
    setIsPipActive(false);
    if (reelPlayerModalRef.current) {
      reelPlayerModalRef.current.style.bottom = "";
      reelPlayerModalRef.current.style.right = "";
      reelPlayerModalRef.current.style.top = "";
      reelPlayerModalRef.current.style.left = "";
    }
  };

  useEffect(() => {
    if (isPipActive) {
      let x = 0;
      let y = 0;
      let disableClick = false;

      // Handle the mousedown event
      // that's triggered when user drags the element
      const mouseDownHandler = function (e: MouseEvent) {
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        disableClick = false;

        // Attach the listeners to `document`
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };
      const mouseMoveHandler = function (e: MouseEvent) {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        const dy = e.clientY - y;

        const reelPlayerModal = reelPlayerModalRef.current;
        // Set the position of element
        reelPlayerModal.style.top = `${Math.min(
          window.innerHeight - reelPlayerModal.offsetHeight,
          Math.max(0, reelPlayerModal.offsetTop + dy)
        )}px`;
        reelPlayerModal.style.left = `${Math.min(
          window.innerWidth - reelPlayerModal.offsetWidth,
          Math.max(0, reelPlayerModal.offsetLeft + dx)
        )}px`;
        reelPlayerModal.style.bottom = "";
        reelPlayerModal.style.right = "";

        // Reassign the position of mouse
        x = e.clientX;
        y = e.clientY;
        disableClick = true;
      };

      const mouseUpHandler = function () {
        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      const touchStartHandler = function (e: TouchEvent) {
        // How far the mouse has been moved
        const touchLocation = e.targetTouches[0];

        x = touchLocation.clientX;
        y = touchLocation.clientY;
        disableClick = false;

        reelPlayerModalRef.current.addEventListener("touchmove", touchMoveHandler);
        document.addEventListener("touchend", touchEndHandler);
      };
      const touchMoveHandler = function (e: TouchEvent) {
        // How far the mouse has been moved
        e.preventDefault();
        e.stopPropagation();
        const touchLocation = e.targetTouches[0];
        const reelPlayerModal = reelPlayerModalRef.current;
        const dx = touchLocation.clientX - x;
        const dy = touchLocation.clientY - y;
        // Set the position of element
        reelPlayerModal.style.top = `${Math.min(
          window.innerHeight - reelPlayerModal.offsetHeight,
          Math.max(0, reelPlayerModal.offsetTop + dy)
        )}px`;
        reelPlayerModal.style.left = `${Math.min(
          window.innerWidth - reelPlayerModal.offsetWidth,
          Math.max(0, reelPlayerModal.offsetLeft + dx)
        )}px`;
        reelPlayerModal.style.bottom = "";
        reelPlayerModal.style.right = "";

        // Reassign the position of mouse
        x = touchLocation.clientX;
        y = touchLocation.clientY;
        disableClick = true;
        return false;
      };

      const touchEndHandler = function () {
        // Remove the handlers of `touchmove` and `touchend`
        reelPlayerModalRef.current.removeEventListener("touchmove", touchMoveHandler);
        document.removeEventListener("touchend", touchEndHandler);

        if (!disableClick) {
          onClosePip();
        } else {
          disableClick = false;
        }
      };

      const videoContainerClickHandler = () => {
        if (!disableClick) {
          onClosePip();
        } else {
          disableClick = false;
        }
      };

      const videoContainerWheelHandler = (e: WheelEvent) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      };

      reelPlayerModalRef.current.addEventListener("mousedown", mouseDownHandler);
      reelPlayerModalRef.current.addEventListener("click", videoContainerClickHandler);
      reelPlayerModalRef.current.addEventListener("touchstart", touchStartHandler);
      reelPlayerModalRef.current.addEventListener("wheel", videoContainerWheelHandler, {
        passive: false,
      });

      return () => {
        onClosePip();
        if (reelPlayerModalRef.current) {
          reelPlayerModalRef.current.removeEventListener("mousedown", mouseDownHandler);
          reelPlayerModalRef.current.removeEventListener("click", videoContainerClickHandler);
          reelPlayerModalRef.current.removeEventListener("wheel", videoContainerWheelHandler);
          reelPlayerModalRef.current.removeEventListener("touchstart", touchStartHandler);
        }
      };
    }
  }, [isPipActive]);

  if (!activeVideo) return null;

  return (
    <>
      <div
        ref={reelPlayerModalRef}
        className={classNames({
          "ins-reel-player-modal-overlay": true,
          "ins-reel-player-modal-overlay__is-mobile": !isDesktop,
          "ins-reel-player-modal-overlay__is-desktop": isDesktop,
          "ins-reel-player-modal-overlay__is-pip-active": isPipActive,
        })}
        onClick={handleClick}
      >
        <div
          ref={modalContentRef}
          className={classNames({
            "ins-reel-player-modal": true,
            "ins-reel-player-modal__is-mobile": !isDesktop,
            "ins-reel-player-modal__is-desktop": isDesktop,
            "ins-reel-player-modal__is-pip-active": isPipActive,
            "ins-reel-player-modal__is-pip-inactive": !isPipActive,
          })}
        >
          {shortVideos
            // .slice(Math.max(0, indexOfActiveVideo - 2), Math.min(videos.length, indexOfActiveVideo + 3))
            .map((video, i, arr) => {
              const indexOfActiveVideo = arr.indexOf(activeVideo);
              const firstInViewVideoIndex = isDesktop
                ? Math.max(0, indexOfActiveVideo - 2)
                : Math.max(0, indexOfActiveVideo - 1);
              const lastInViewVideoIndex = isDesktop
                ? Math.min(shortVideos.length, indexOfActiveVideo + 3)
                : Math.min(shortVideos.length, indexOfActiveVideo + 2);
              const numberOfActiveVideos = lastInViewVideoIndex - firstInViewVideoIndex;
              const isInView = firstInViewVideoIndex <= i && lastInViewVideoIndex > i;

              return (
                <Reel
                  isDesktop={isDesktop}
                  isInView={isInView}
                  key={video.id}
                  isActive={video.id === activeVideo.id}
                  onView={onView}
                  video={video}
                  stackIndex={
                    i < indexOfActiveVideo
                      ? i - firstInViewVideoIndex
                      : lastInViewVideoIndex - i - 1
                  }
                  sizeMultiplier={
                    i < indexOfActiveVideo ? indexOfActiveVideo - i : i - indexOfActiveVideo
                  }
                  isPrevious={i < indexOfActiveVideo}
                  onClick={() => setActiveVideoId(video.id)}
                  onClose={handleModalClose}
                  goToNextVideo={() => (arr[i + 1] ? setActiveVideoId(arr[i + 1].id) : null)}
                  goToPreviousVideo={() => (arr[i - 1] ? setActiveVideoId(arr[i - 1].id) : null)}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default VideoModal;
