import { createContext } from "preact";
import { useContext, useMemo } from "preact/hooks";
import { memo } from "preact/compat";
import { MAIN_API_BASE_URL } from "./constants";
import {
  GetShortVideosRequestBody,
  GetShortVideosResponse,
  ShortVideosBoronRequestBody,
  ShortVideosBoronResponse,
} from "../types/api";
export interface IApiService {
  /**
   * Get short videos using the rest api
   */
  getShortVideos(params: {
    originFqdn: string;
    viewerToken?: string;
    playlistId: string;
  }): Promise<GetShortVideosResponse>;
  /**
   * Send a boron event to server
   */
  shortVideosBoron(body: ShortVideosBoronRequestBody): Promise<ShortVideosBoronResponse>;
}

export const ApiContext = createContext<IApiService>(null as never);

export class ApiService implements IApiService {
  async getShortVideos({
    originFqdn,
    viewerToken,
    playlistId,
  }: {
    originFqdn: string;
    viewerToken?: string;
    playlistId: string;
  }): Promise<GetShortVideosResponse> {
    const requestBody: GetShortVideosRequestBody = {
      originFqdn,
      viewerToken: viewerToken,
      playlistId,
    };
    const res = await fetch(`${MAIN_API_BASE_URL}/_/GetShortVideos`, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    const json = await res.json();
    return json as GetShortVideosResponse;
  }
  async shortVideosBoron(body: ShortVideosBoronRequestBody): Promise<ShortVideosBoronResponse> {
    const requestBody: ShortVideosBoronRequestBody = body;
    const pastViewerToken = localStorage?.getItem("__IS_VTOK") ?? undefined;
    const res = await fetch(`${MAIN_API_BASE_URL}/_/ShortVideosBoron`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Authorization: `Bearer ${pastViewerToken}`,
      },
    });
    const json = await res.json();
    return json as GetShortVideosResponse;
  }
}

export const standardApiClient = new ApiService();

export const ApiContextProvider = memo(({ children }) => {
  const apiClient = useMemo(() => standardApiClient, []);
  return <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>;
});

export const useApi = () => {
  const apiClient = useContext(ApiContext);
  return apiClient;
};
