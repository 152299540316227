export const isDev = process.env.NODE_ENV == "development";

export const MAX_MOBILE_WIDTH = 768;

export const MAIN_API_BASE_URL = process.env.MAIN_API_BASE_URL
  ? process.env.MAIN_API_BASE_URL
  : isDev
  ? "http://127.0.0.1:8080"
  : "https://jrfpnhhzq7.us-east-1.awsapprunner.com";

export const CHAT_API_BASE_URL = process.env.CHAT_API_BASE_URL
  ? process.env.CHAT_API_BASE_URL
  : isDev
  ? "http://localhost:8084"
  : "https://pp4vaiiifm.us-east-1.awsapprunner.com";
