import { instasellLiveEmbedConfig } from "../..";
import ProductDetailsModal from "../ProductDetailsModal";
import { useShortVideosModalContext } from "../../context/ShortVideosModalContext";
import type { ShortVideo } from "../../types/api";
import { formatCurrency } from "../../lib/utils";

const ProductPane = ({
  video,
  isDesktop,
  isActive,
}: {
  video: ShortVideo;
  isDesktop: boolean;
  isActive: boolean;
}) => {
  const { setActiveProductIndex, setIsProductDetailsModalOpen } =
    useShortVideosModalContext();
  let currencyCode = instasellLiveEmbedConfig.getCurrencyCode?.() || "INR";
  const productToDisplay = video.products[0];

  // TODO: this code should be removed after the following site updates their config
  switch (window.location.hostname) {
    case "instasell-live-commerce.webflow.io":
    case "instasell.io":
    case "www.twoprettygirlz.com":
    case "two-pretty-girlz.myshopify.com":
      currencyCode = "USD";
      break;
  }

  if (!productToDisplay) return null;

  if (!isActive && isDesktop) return null;
  const openProductDetailsModal = (i: number) => {
    if (
      window.location.hostname === "minify.sg" ||
      window.location.hostname === "www.camecompany.com" ||
      window.location.hostname === "pebirds.com"
    ) {
      const product = video.products[i];
      window.location.href = product.permalink;
    } else {
      setActiveProductIndex(i);
      setIsProductDetailsModalOpen(true);
    }
  };
  return (
    <>
      {isActive ? <ProductDetailsModal /> : null}
      <div className="ins-reel-player-product-panel-2">
        {video.products.map((product, i) => (
          <div
            className="ins-product-panel-item"
            onClick={() => openProductDetailsModal(i)}
          >
            <div
              className="ins-product-panel-item-thumbnail"
              style={{
                backgroundImage: `url(${product.image})`,
              }}
            ></div>
            <div className="ins-product-panel-item-details">
              <div>
                <p className="ins-product-panel-item-title">{product.title}</p>
                <p className="ins-product-panel-item-price">
                  {formatCurrency(
                    product.variants[0]?.price || 0,
                    currencyCode
                  )}
                  {product.variants[0]?.comparePrice ? (
                    <span className="ins-product-panel-item-strikeoff-price">
                      {formatCurrency(
                        product.variants[0]?.comparePrice || 0,
                        currencyCode
                      )}
                    </span>
                  ) : null}
                </p>
              </div>
              <p className="ins-product-panel-item-buy-now">Shop Now {">"}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="ins-reel-player-product-panel">
        <div
          className="ins-product-panel-item"
          onClick={isDesktop ? () => setIsOpen(true) : openProductDetailsModal}
        >
          <div
            className="ins-product-panel-item-thumbnail"
            style={{
              backgroundImage: `url(${productToDisplay.image})`,
            }}
          ></div>
          <div className="ins-product-panel-item-details">
            <p className="ins-product-panel-item-title">{productToDisplay.title}</p>
            <p className="ins-product-panel-item-price">
              {currencyIcon}
              {productToDisplay.variants[0]?.price}
              {productToDisplay.variants[0]?.comparePrice ? (
                <span className="ins-product-panel-item-strikeoff-price">
                  {currencyIcon}
                  {productToDisplay.variants[0]?.comparePrice}
                </span>
              ) : null}
            </p>
          </div>
        </div>
        <div className="ins-product-panel-actions">
          <button
            className="ins-product-panel-action-shop-now-button"
            onClick={isDesktop ? () => setIsOpen(true) : openProductDetailsModal}
          >
            Shop Now
          </button>
          <button
            className="ins-product-panel-action-cart-button"
            onClick={() => instasellLiveEmbedConfig.redirectToCart()}
          >
            <CartIcon className="ins-product-panel-action-cart-icon" />
          </button>
        </div>
      </div> */}
    </>
  );
};

export default ProductPane;
