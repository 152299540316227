import { Code } from "@connectrpc/connect";
import { useEffect } from "preact/hooks";
import VideoModal from "../VideoModal";
import ShoppableReelsFeed from "./shoppable-reels-feed";
import { useApi } from "../../lib/api";
import ShoppableReelsFeedSkeleton from "./shoppable-reels-feed-skeleton";
import { useShortVideosModalContext } from "../../context/ShortVideosModalContext";
import { PreactPortal } from "../Portal";
import { instasellLiveEmbedConfig } from "../..";

const ShowShoppableReels = () => {
  const api = useApi();
  const {
    setShortVideos,
    isLoadingShortVideos,
    setIsLoadingShortVideos,
    activeVideoId,
    setActiveVideoId,
    setIsPipActive,
    showFeed,
    setShowFeed,
  } = useShortVideosModalContext();

  const fetchShortVideos = async () => {
    const pastViewerToken = localStorage?.getItem("__IS_VTOK") ?? undefined;

    const shortVideosResponse = await api
      .getShortVideos({
        originFqdn:
          process.env.STAGING_DEPLOY == "1" ||
          ["localhost", "192.168.1.33", "127.0.0.1"].includes(window.location.hostname)
            ? "archi-cosmetics.myshopify.com"
            : window.location.hostname,
        viewerToken: pastViewerToken,
        playlistId: String(instasellLiveEmbedConfig.playlistId || ""),
      })
      .catch((e) => {
        if (e.code == Code.NotFound) {
          return null;
        }
        throw e;
      });
    if (shortVideosResponse != null) {
      if (shortVideosResponse.shortVideos) {
        setShortVideos(
          shortVideosResponse.shortVideos.filter((shortVideo) => shortVideo.products?.length > 0)
        );
        const lastActiveVideoId = localStorage.getItem("__IS_LAST_ACTIVE_SV")
          ? JSON.parse(localStorage.getItem("__IS_LAST_ACTIVE_SV") || "")
          : null;

        if (
          instasellLiveEmbedConfig.getPageType?.() === "product" &&
          instasellLiveEmbedConfig.getActiveProductPageId?.()
        ) {
          const shortVideoAssociatedWithProducts = shortVideosResponse.shortVideos.filter(
            (shortVideo) =>
              shortVideo.products?.some(
                (product) =>
                  instasellLiveEmbedConfig?.getActiveProductPageId?.() == product.providerProductId
              )
          );
          if (shortVideoAssociatedWithProducts.length > 0) {
            setShortVideos(shortVideoAssociatedWithProducts);
            setActiveVideoId(shortVideoAssociatedWithProducts[0].id);
            setIsPipActive(true);
          }
        } else if (lastActiveVideoId) {
          if (
            shortVideosResponse.shortVideos.find(
              (shortVideo) => lastActiveVideoId === shortVideo.id
            )
          ) {
            setActiveVideoId(lastActiveVideoId);
            setIsPipActive(true);
          }
        }
      }
      setIsLoadingShortVideos(false);

      localStorage.setItem("__IS_VTOK", shortVideosResponse.viewerToken);
    }
  };
  useEffect(() => {
    if (
      instasellLiveEmbedConfig.getPageType?.() !== "home" &&
      instasellLiveEmbedConfig.showFeedOnHomePageOnly
    ) {
      setShowFeed(false);
    }
    fetchShortVideos();
  }, []);

  return (
    <div class="ins-shoppable-videos">
      {isLoadingShortVideos ? (
        <ShoppableReelsFeedSkeleton />
      ) : showFeed ? (
        <ShoppableReelsFeed />
      ) : null}
      {activeVideoId ? (
        <PreactPortal>
          <div className="ins-shoppable-videos">
            <VideoModal />
          </div>
        </PreactPortal>
      ) : null}
    </div>
  );
};

export default ShowShoppableReels;
