import { useEffect, useRef, useState } from "preact/hooks";
import { classNames } from "../../lib/utils";
import ProductPane from "../ProductPane";
import {
  PauseIcon,
  PlaySolidIcon,
  XIcon,
  SpeakerXMarkIcon,
  SpeakerWaveIcon,
} from "../icons";
import { useShortVideosModalContext } from "../../context/ShortVideosModalContext";

import type { ShortVideo } from "../../types/api";

const Reel = ({
  isActive,
  video,
  sizeMultiplier,
  stackIndex,
  isPrevious,
  onClick,
  isInView,
  isDesktop,
  onClose,
  onView,
  goToNextVideo,
  goToPreviousVideo,
}: {
  isInView: boolean;
  isActive: boolean;
  video: ShortVideo;
  sizeMultiplier: number;
  stackIndex: number;
  isPrevious: boolean;
  isDesktop: boolean;
  onClick: () => void;
  goToNextVideo: () => void;
  goToPreviousVideo: () => void;
  onClose: () => void;
  onView: (shortVideoId: string) => void;
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { isPipActive, setIsPipActive } = useShortVideosModalContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const reelPlayer = useRef<HTMLDivElement>(null as never);
  const videoPlayer = useRef<HTMLVideoElement | null>(null);
  const requiredWidth = Math.min(
    (window.innerHeight * 9) / 16,
    window.innerWidth
  );
  const dimensionsMultiplier = isActive ? 1 : Math.pow(0.8, sizeMultiplier);
  const videoProgressBarRef = useRef<HTMLDivElement>(null);
  const videoUrl = video.medias.find((media) => media.size === "high")?.videoUrl

  useEffect(() => {
    if (!isInView && isVideoLoaded) {
      setIsVideoLoaded(false);
    }
    if (isInView) {
      window.setTimeout(() => {
        if (isVideoLoaded) {
          if (videoPlayer.current) {
            videoPlayer.current?.pause();
            videoPlayer.current.currentTime = 0;
            try {
              videoPlayer.current?.play();
            } catch (error) {
              window.setTimeout(() => {
                videoPlayer.current?.play();
              }, 1000);
            }
          }
        }
      }, 1000);
    }
  }, [isInView]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    var yDown: number | null = null;

    function handleTouchStart(e: TouchEvent) {
      const firstTouch = e.touches[0];
      yDown = firstTouch.clientY;
      reelPlayer.current.style.transition = "none";

      const nextSibling = reelPlayer.current?.nextSibling as HTMLDivElement;
      if (nextSibling?.style) {
        nextSibling.style.transition = "none";
      }

      const previousSibling = reelPlayer.current
        ?.previousSibling as HTMLDivElement;
      if (previousSibling?.style) {
        previousSibling.style.transition = "none";
      }
    }

    function handleTouchMove(e: TouchEvent) {
      const lastTouch = e.changedTouches[0];
      if (!yDown) {
        return;
      }

      const yDiff = lastTouch.clientY - yDown;
      reelPlayer.current.style.top = `${Math.floor(yDiff)}px`;
      if (yDiff > 0) {
        const previousSibling = reelPlayer.current
          ?.previousSibling as HTMLDivElement;
        if (previousSibling?.style) {
          previousSibling.style.top = `calc(-100% + ${yDiff}px)`;
        }
      } else {
        const nextSibling = reelPlayer.current?.nextSibling as HTMLDivElement;
        if (nextSibling?.style) {
          nextSibling.style.top = `calc(100% + ${yDiff}px)`;
        }
      }
      // if (yDiff > 0) {
      //   reelPlayer.current.style.top = `calc(0 - ${yDiff})`;
      //   // reelPlayer.current.previousElementSibling = ""
      // } else {
      // }
    }

    function handleTouchEnd(e: TouchEvent) {
      const lastTouch = e.changedTouches[0];
      if (!yDown) {
        return;
      }

      reelPlayer.current.style.transition = "";
      reelPlayer.current.style.top = "0";
      const nextSibling = reelPlayer.current?.nextSibling as HTMLDivElement;
      if (nextSibling?.style) {
        // nextSibling.style.top = "100%";
        nextSibling.style.transition = "";
      }

      const previousSibling = reelPlayer.current
        ?.previousSibling as HTMLDivElement;
      if (previousSibling?.style) {
        // previousSibling.style.top = "-100%";
        previousSibling.style.transition = "";
      }

      const yDiff = lastTouch.clientY - yDown;
      if (Math.abs(yDiff) > 30) {
        if (yDiff > 0) {
          // go up
          goToPreviousVideo();
        } else {
          // go down
          goToNextVideo();
        }
      }
      yDown = null;
    }

    reelPlayer.current.addEventListener("touchstart", handleTouchStart);
    reelPlayer.current.addEventListener("touchmove", handleTouchMove);
    reelPlayer.current.addEventListener("touchend", handleTouchEnd);
    return () => {
      reelPlayer.current.removeEventListener("touchstart", handleTouchStart);
      reelPlayer.current.removeEventListener("touchmove", handleTouchMove);
      reelPlayer.current.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isActive]);

  useEffect(() => {
    onView(video.id);

    window.setTimeout(() => {
      if (isVideoLoaded) {
        if (videoPlayer.current) {
          videoPlayer.current.currentTime = 0;
          videoPlayer.current?.play();
        }
      }
    }, 0);

    const id = window.setInterval(() => {
      if (!videoProgressBarRef.current) return;
      videoProgressBarRef.current.style.width = `${
        Math.round(
          ((videoPlayer.current?.currentTime || 0) /
            (videoPlayer.current?.duration || 0)) *
            10000
        ) / 100
      }%`;
    }, 100);

    return () => {
      window.clearInterval(id);
    };
  }, [isActive]);

  useEffect(() => {
    if (!isPlaying && isMuted && isVideoLoaded) {
      videoPlayer.current?.play();
    }
  }, [isMuted]);

  return (
    <div
      ref={reelPlayer}
      className={classNames({
        "ins-reel-player-modal-reel": true,
        "ins-reel-player-modal-reel__is-active": isActive,
        "ins-reel-player-modal-reel__is-mobile": !isDesktop,
        "ins-reel-player-modal-reel__is-desktop": isDesktop,
        "ins-reel-player-modal-reel__is-pip-active": isPipActive,
        "ins-reel-player-modal-reel__is-pip-inactive": !isPipActive,
      })}
      style={{
        ...(isActive
          ? {
              width: isDesktop ? requiredWidth + "px" : "100%",
            }
          : isInView
          ? isDesktop
            ? {
                left: 50 + (isPrevious ? -1 : 1) * 12.5 * sizeMultiplier + "%",
                height: window.innerHeight * dimensionsMultiplier + "px",
                width: requiredWidth * dimensionsMultiplier + "px",
                "z-index": 1100 + stackIndex,
                transform: "translateX(-50%)",
              }
            : {
                position: "absolute",
                top: isPrevious ? "-100%" : "100%",
                width: "100%",
                left: "0",
                height: "100%",
              }
          : isDesktop
          ? {
              "z-index": 0,
              transform: "translateX(-100%)",
              left: isPrevious ? "-25%" : "125%",
              height: window.innerHeight * 0.01 + "px",
              width: requiredWidth * 0.01 + "px",
            }
          : {
              top: isPrevious ? "-200%" : "200%",
              width: "100%",
              left: "0",
              height: "100%",
            }),
      }}
    >
      {isInView || !isDesktop ? (
        <video
          onClick={!isActive ? onClick : () => {}}
          className={classNames({
            "ins-reel-player-modal-reel-video__in-view": true,
            "ins-reel-player-modal-reel-video__is-active": isActive,
            "ins-reel-player-modal-reel-video__is-desktop": !!isDesktop,
          })}
          // src={isActive || isVideoLoaded ? videoUrl : previewVideoUrl}
          src={videoUrl}
          autoPlay={true}
          ref={videoPlayer}
          onLoadedMetadata={() => {
            if (videoPlayer.current?.src === videoUrl) {
              setIsVideoLoaded(true);
            }
          }}
          loop={true}
          preload="metadata"
          muted={isMuted || !isActive}
          controls={false}
          allowFullScreen={false}
          playsInline={true}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
        />
      ) : null}

      {isInView ? (
        <>
          {isActive ? (
            <>
              {isPipActive ? (
                <>
                  <div
                    className="ins-reel-modal-player-pip-top-controls"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onTouchStart={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <button
                      onClick={() => setIsMuted(!isMuted)}
                      className="ins-reel-modal-player-pip-close-button"
                    >
                      {isMuted ? (
                        <SpeakerXMarkIcon className="ins-reel-modal-player-pause-icon" />
                      ) : (
                        <SpeakerWaveIcon className="ins-reel-modal-player-play-icon" />
                      )}
                    </button>
                    <button
                      onClick={onClose}
                      className="ins-reel-modal-player-pip-close-button"
                    >
                      <XIcon className="ins-reel-modal-player-pip-close-button-icon" />
                    </button>
                  </div>
                  <button
                    className="ins-reel-modal-player-pip-play-controls"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (videoPlayer.current?.paused) {
                        videoPlayer.current?.play();
                      } else {
                        videoPlayer.current?.pause();
                      }
                    }}
                  >
                    {isPlaying ? (
                      <PauseIcon className="ins-reel-modal-player-pause-icon" />
                    ) : (
                      <PlaySolidIcon className="ins-reel-modal-player-play-icon" />
                    )}
                  </button>
                </>
              ) : (
                <>
                  <div className="ins-reel-modal-player-top-controls">
                    <button
                      onClick={onClose}
                      className="ins-reel-modal-player-close-button"
                    >
                      <XIcon className="ins-reel-modal-player-close-icon" />
                    </button>
                  </div>
                </>
              )}
              <div className="ins-reel-player-modal-reel-progress-meter">
                <div
                  className="ins-reel-player-modal-reel-progress-bar"
                  ref={videoProgressBarRef}
                ></div>
              </div>
            </>
          ) : null}

          {isPipActive ? null : (
            <ProductPane
              video={video}
              isDesktop={isDesktop}
              isActive={isActive}
            />
          )}
        </>
      ) : null}
      {/* )} */}
    </div>
  );
};

export default Reel;
