import { MutableRef, useEffect, useRef } from "preact/hooks";
import handleViewport from "react-in-viewport";
import { useState } from "react";
import { ChevronDownIcon } from "../icons";
import { useShortVideosModalContext } from "../../context/ShortVideosModalContext";
import { instasellLiveEmbedConfig } from "../..";
import { formatCurrency } from "../../lib/utils";

import type { ShortVideo } from "../../types/api";

type ShoppableReelsFeedProps = {};

const ShoppableReelsFeed = ({}: ShoppableReelsFeedProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { shortVideos, setActiveVideoId, isDesktop, setIsPipActive } = useShortVideosModalContext();

  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const scrollHandler = () => {
    if (!scrollContainerRef.current) return;
    if (scrollContainerRef.current?.scrollLeft > 0) {
      setShowPreviousButton(true);
    } else {
      setShowPreviousButton(false);
    }
    if (
      scrollContainerRef.current?.scrollWidth -
        (scrollContainerRef.current?.scrollLeft + scrollContainerRef.current?.clientWidth) >
      10
    ) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  };
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    scrollHandler();
    scrollContainerRef.current.addEventListener("scroll", scrollHandler);

    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  useEffect(() => {
    scrollHandler();
  }, [shortVideos.length]);

  const moveForward = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current?.scrollLeft + scrollContainerRef.current?.clientWidth,
      behavior: "smooth",
    });
  };
  const moveBackward = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current?.scrollLeft - scrollContainerRef.current?.clientWidth,
      behavior: "smooth",
    });
  };

  return (
    <div class="ins-shoppable-video-feed-wrapper">
      {isDesktop && showPreviousButton ? (
        <button
          className="ins-shoppable-video-feed-nav-button ins-shoppable-video-feed-nav-prev"
          onClick={moveBackward}
        >
          <ChevronDownIcon className="ins-shoppable-video-feed-nav-button-icon"></ChevronDownIcon>
        </button>
      ) : null}
      {isDesktop && showNextButton ? (
        <button
          className="ins-shoppable-video-feed-nav-button ins-shoppable-video-feed-nav-next"
          onClick={moveForward}
        >
          <ChevronDownIcon className="ins-shoppable-video-feed-nav-button-icon"></ChevronDownIcon>
        </button>
      ) : null}
      <div class="ins-shoppable-video-feed" ref={scrollContainerRef}>
        {shortVideos.map((video) => {
          return (
            <ShoppableReelPreviewCard
              video={video}
              setCurrentItemActive={() => {
                setIsPipActive(false);
                setActiveVideoId(video.id);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ShoppableReelsFeed;

const ShoppableReelPreviewCard = handleViewport(
  ({
    video,
    setCurrentItemActive,
    inViewport,
    forwardedRef,
  }: {
    video: ShortVideo;
    setCurrentItemActive: () => void;
    inViewport: boolean;
    forwardedRef: MutableRef<HTMLDivElement>;
  }) => {
    let currencyCode = instasellLiveEmbedConfig.getCurrencyCode?.() || "INR";
    const [loadVideo, setLoadVideo] = useState(false);
    const previewVideoUrl = video.medias.find((media) => media.size == "high")?.videoUrl;
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (inViewport) {
        if (!loadVideo) {
          setLoadVideo(true);
        } else {
          videoRef.current?.play();
        }
      } else {
        if (loadVideo) {
          videoRef.current?.pause();
        }
      }
    }, [inViewport]);

    // TODO: this code should be removed after the following site updates their config
    switch (window.location.hostname) {
      case "instasell-live-commerce.webflow.io":
      case "instasell.io":
      case "www.twoprettygirlz.com":
      case "two-pretty-girlz.myshopify.com":
        currencyCode = "USD";
        break;
    }

    return (
      <div
        key={video.id}
        class="ins-shoppable-video-card"
        onClick={setCurrentItemActive}
        ref={forwardedRef}
      >
        {loadVideo ? (
          <video
            className="ins-shoppable-video-card-preview"
            src={previewVideoUrl}
            autoPlay={true}
            ref={videoRef}
            loop={true}
            preload="auto"
            controls={false}
            allowFullScreen={false}
            muted={true}
            playsInline={true}
            onTimeUpdate={(e) => {
              if (e.currentTarget.currentTime >= 3) {
                e.currentTarget.currentTime = 0;
              }
            }}
          />
        ) : (
          <div className="ins-shoppable-video-card-preview"></div>
        )}
        <div className="ins-shoppable-video-card-product">
          {video.products[0] ? (
            <>
              <div className="ins-shoppable-video-card-product-image-container">
                <img
                  className="ins-shoppable-video-card-product-image"
                  loading="lazy"
                  src={video.products[0].image}
                  alt={video.products[0].title}
                />
              </div>
              <h3 className="ins-shoppable-video-card-product-name">{video.products[0].title}</h3>
              <p className="ins-shoppable-video-card-product-price">
                {formatCurrency(video.products[0].variants[0]?.price || 0, currencyCode)}
              </p>
            </>
          ) : null}
        </div>
      </div>
    );
  },
  { root: document.querySelector(".ins-shoppable-video-feed") }
);
