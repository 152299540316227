import { instasellLiveEmbedConfig } from "..";

export const classNames = (classes: Record<string, boolean>) => {
  return Object.keys(classes)
    .filter((className) => classes[className])
    .join(" ");
};

export const getSkeletonLength = () => {
  return instasellLiveEmbedConfig.skeletonLength || 6;
};

export const formatCurrency = (amount: number, currency: string = "INR") => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      // maximumSignificantDigits: 0,
      // minimumSignificantDigits: 0,
    }).format(amount);
  } catch (err) {
    return `₹${amount}`;
  }
};
